import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: '/',
    redirect: '/login',
    component: () => import('@/views/login.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue')
  },
  {
    path: '/loginQR',
    name: 'loginQR',
    component: () => import('@/views/loginQR.vue')
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: () => import('@/views/forgotPassword.vue')
  },
  {
    path: '/setNewPassword',
    name: 'setNewPassword',
    component: () => import('@/views/setNewPassword.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register.vue')
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 }
  },
})

// 路由守卫--start
router.beforeEach((to, from, next) => {
  sessionStorage.removeItem("showSwitchRole");
  sessionStorage.removeItem("isNotice");
  sessionStorage.removeItem("isNoticeAll");
  sessionStorage.removeItem("mandatoryFrontPage");
  next();
});

// 路由守卫--end
export default router
